<template>
  <header
    class="py-0 flex items-center relative z-50"
    :class="{
      'bg-brand-blue': color === 'blue' && !subAgencyLogo,
    }"
  >
    <div class="relative w-full">
      <Popover class="">
        <div class="mx-auto p-4 relative flex items-center w-full">
          <nav
            class="relative flex items-center justify-between w-full"
            aria-label="Global"
          >
            <div class="flex w-full items-center justify-between lg:w-auto">
              <a
                href="/"
                class="relative block hover:before:hidden"
                data-test="header.logo"
                aria-label="Go to home page"
              >
                <!-- <Logo class="w-[80px] lg:w-[122px]"></Logo> -->
                <img
                  v-if="color === 'blue' && !subAgencyLogo"
                  src="@/assets/logo-white.svg"
                  alt="Logo"
                  class="w-[201px]"
                />

                <img
                  v-else
                  src="@/assets/logo-blue.svg"
                  alt="Logo"
                  class="w-[201px]"
                />
              </a>
              <div
                class="-mr-2 flex items-center lg:hidden"
                :class="{ '!hidden': subAgencyLogo }"
              >
                <a
                  href="/login"
                  class="text-sm mr-6 relative top-[1px]"
                  :class="{
                    'text-white': color === 'blue' && !subAgencyLogo,
                    'text-brand-blue': color !== 'blue',
                  }"
                  data-test="header.login"
                  >Login</a
                >
                <PopoverButton
                  class="inline-flex items-center justify-center rounded-md bg-gray-50 p-2 text-gray-400 hover:bg-gray-100 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-brand-blue"
                >
                  <span class="sr-only">Open main menu</span>
                  <Bars3Icon class="h-6 w-6" aria-hidden="true" />
                </PopoverButton>
              </div>
            </div>

            <!-- navigation -->
            <nav
              id="desktopHeader"
              class="hidden lg:flex items-center space-x-8"
            >
              <!-- <slot /> -->
              <ul v-if="!subAgencyLogo" class="flex space-x-6 text-brand-blue">
                <li
                  v-for="(item, index) in items"
                  class="flex space-x-1 items-center px-4 py-2 rounded-lg text-sm cursor-pointer text-brand-blue transition"
                  :class="{
                    'bg-[#E0EAF3] text-brand-blue':
                      selectedSubNav === item.subNav,
                    ' hover:bg-brand-blue/10':
                      selectedSubNav !== item.subNav && color !== 'blue',
                    ' hover:bg-white/10':
                      (selectedSubNav !== item.subNav && color === 'blue') ||
                      subAgencyLogo,
                    'text-white':
                      color === 'blue' ||
                      (subAgencyLogo && selectedSubNav !== item.subNav),
                  }"
                  data-test="header.subNav"
                  @click="
                    () => {
                      if (selectedSubNav === item.subNav) {
                        selectedItem = null
                        selectedSubNav = null
                      } else {
                        selectedItem = item
                        selectedSubNav = item.subNav
                        setBlueDivHeight(item)
                      }
                    }
                  "
                >
                  <span v-if="item.subNav?.length > 0">{{ item.name }}</span>
                  <a
                    :href="item.path"
                    v-else
                    class="block h-full w-full"
                    data-test="header.name"
                    :target="item.target"
                    >{{ item.name }}</a
                  >
                  <svg
                    v-if="item.subNav"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                    class="w-5 h-5"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M5.22 8.22a.75.75 0 0 1 1.06 0L10 11.94l3.72-3.72a.75.75 0 1 1 1.06 1.06l-4.25 4.25a.75.75 0 0 1-1.06 0L5.22 9.28a.75.75 0 0 1 0-1.06Z"
                      clip-rule="evenodd"
                    />
                  </svg>
                </li>
              </ul>
            </nav>
            <div
              v-if="!subAgencyLogo"
              class="hidden lg:flex space-x-6 items-center"
            >
              <a
                href="/login"
                class="text-sm font-wide text-brand-blue"
                :class="{
                  '!text-white': color === 'blue' && !subAgencyLogo,
                }"
                data-test="header.logIn"
                >Log In</a
              >
              <a
                :href="linkUrl"
                class="block bg-brand-blue/25 hover:bg-brand-blue/40 text-brand-blue uppercase font-wide tracking-[1px] px-6 !py-2.5 text-center font-extrabold w-auto text-sm rounded-full transition"
                :class="{
                  '!bg-white !text-brand-blue':
                    color === 'blue' && !subAgencyLogo,
                }"
              >
                <span class="relative top-[1px]" data-test="header.getQuote">
                  Get Quote
                </span>
              </a>
            </div>
            <img
              v-else-if="subAgencyLogo"
              class="inline-block max-h-[50px] mt-2"
              data-test="header.subAgencyLogo"
              :src="subAgencyLogo"
              alt="Sub-Agency Logo"
            />
            <!-- /navigation -->
          </nav>
        </div>

        <transition
          enter-active-class="duration-150 ease-out"
          enter-from-class="opacity-0 scale-95"
          enter-to-class="opacity-100 scale-100"
          leave-active-class="duration-100 ease-in"
          leave-from-class="opacity-100 scale-100"
          leave-to-class="opacity-0 scale-95"
        >
          <PopoverPanel
            focus
            class="absolute inset-x-0 top-0 z-10 origin-top-right transform p-2 transition lg:hidden"
          >
            <div
              class="rounded-lg bg-white shadow-md ring-1 ring-black ring-opacity-5"
            >
              <div class="flex items-center justify-between px-5 pt-4">
                <div>
                  <img
                    src="@/assets/logo-blue.svg"
                    alt="Logo"
                    class="w-[191px]"
                  />
                </div>
                <div class="-mr-2">
                  <PopoverButton
                    class="inline-flex items-center justify-center rounded-md bg-white p-2 text-gray-400 hover:bg-gray-100 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-brand-blue"
                  >
                    <span class="sr-only">Close menu</span>
                    <XMarkIcon class="h-6 w-6" aria-hidden="true" />
                  </PopoverButton>
                </div>
              </div>
              <div id="mobileHeader" class="px-2 pt-2 pb-3">
                <div
                  class="relative grid gap-6 bg-white px-5 py-6 sm:gap-8 sm:p-8 lg:grid-cols-2"
                >
                  <a
                    v-for="item in allSubNavs"
                    :key="item.name"
                    :href="item.path"
                    class="-m-3 flex items-start rounded-lg p-3 hover:bg-gray-50"
                  >
                    <div
                      class="flex h-10 w-10 flex-shrink-0 items-center justify-center rounded-md bg-brand-blue text-white sm:h-12 sm:w-12"
                    >
                      <div v-html="item.icon" class="h-5 w-5"></div>
                    </div>
                    <div class="ml-4">
                      <p
                        class="text-sm font-medium text-gray-900 relative top-[3px]"
                      >
                        {{ item.name }}
                      </p>
                      <p class="mt-0.5 text-xs text-gray-500">
                        {{ item.description }}
                      </p>
                    </div>
                  </a>
                </div>
              </div>
            </div>
          </PopoverPanel>
        </transition>
      </Popover>
    </div>

    <Teleport v-if="loaded" to=".search-wrapper">
      <transition
        enter-active-class="duration-150 ease-out origin-top-right"
        enter-from-class="opacity-0 scale-95"
        enter-to-class="opacity-100 scale-100"
        leave-active-class="duration-100 ease-in origin-top-right"
        leave-from-class="opacity-100 scale-100"
        leave-to-class="opacity-0 scale-95"
      >
        <div
          v-if="isSearchOpen"
          class="absolute top-[110%] bg-white p-4 border border-gray-100 shadow-sm right-[-20px]"
        >
          <form action="/" method="GET">
            <label
              for="search-input"
              class="font-serif font-sm block"
              data-test="header.searchSite"
            >
              Search Site
            </label>
            <div class="flex items-stretch space-x-1">
              <input
                id="search-input"
                name="s"
                type="text"
                class="bg-white border border-[#D6D3D1] input:!mb-4 input:!mb-0 focus:border-brand-teal focus:ring-brand-teal"
                data-test="header.searchInput"
              />
              <button
                type="submit"
                class="bg-brand-teal px-3 py-2 border border-brand-teal hover:bg-brand-teal/75 transition duration-150 text-sm h-[42px] flex items-center font-bold"
                data-test="header.searchSubmit"
              >
                Submit
              </button>
            </div>
          </form>
        </div>
      </transition>
    </Teleport>
    <nav @mouseleave="animateSelectionOut">
      <div class="absolute inset-x-0 top-full z-20 subnav">
        <div
          v-if="selectedSubNav"
          :key="selectedSubNav"
          :class="`relative bg-[#E0EAF3] grid origin-top min-h-[112px] ${
            selectedItem?.gridCols === 5
              ? 'grid-cols-5'
              : selectedItem?.gridCols === 4
              ? 'grid-cols-4'
              : 'grid-cols-3'
          }`"
        >
          <div
            ref="blueDiv"
            class="scale-0 absolute rounded-lg"
            :class="{
              'bg-brand-blue': page !== 'blue',
              'bg-brand-teal': page === 'blue',
            }"
          ></div>
          <a
            :href="item.path"
            v-for="(item, index) in selectedSubNav"
            @mouseenter="animateSelection($event, index)"
            :target="item.target"
            class="block p-6 h-full grid items-center"
          >
            <span class="flex items-center space-x-4 pointer-events-none">
              <span
                v-html="item.icon"
                :class="{
                  'text-white': selectedIndex === index,
                  'text-brand-blue/50': selectedIndex !== index,
                }"
                class="transition duration-200 delay-75"
              ></span>
              <span>
                <span
                  class="block text-sm transition duration-200 delay-75"
                  :class="{
                    'text-white': selectedIndex === index,
                    'text-neutral-950': selectedIndex !== index,
                  }"
                  data-test="header.subName"
                >
                  {{ item.name }}
                </span>
                <span
                  class="block text-xs transition duration-200 delay-75"
                  :class="{
                    'text-white': selectedIndex === index,
                    'text-neutral-600': selectedIndex !== index,
                  }"
                  data-test="header.subDescription"
                >
                  {{ item.description }}
                </span>
              </span>
            </span>
          </a>
        </div>
      </div>
    </nav>
  </header>
</template>

<script setup>
import {
  Popover,
  PopoverButton,
  PopoverGroup,
  PopoverPanel,
} from '@headlessui/vue'
import {
  ArrowPathIcon,
  Bars3Icon,
  ChartBarIcon,
  CursorArrowRaysIcon,
  DocumentChartBarIcon,
  ShieldCheckIcon,
  Squares2X2Icon,
  XMarkIcon,
} from '@heroicons/vue/24/outline'
import { ChevronDownIcon } from '@heroicons/vue/20/solid'
import { defineProps, onMounted, ref, nextTick, computed } from 'vue'
import gsap from 'gsap'
import { useStore } from 'vuex'
import { useUTMLink } from '@/composables/useUTMLink'

const store = useStore()
const subAgencyLogo = computed(() => {
  return store?.state?.subagency?.agencyData?.logo
})

const { data, color } = defineProps(['data', 'color'])
const { linkUrl } = useUTMLink(data?.header_get_quote_link?.url)

const isSearchOpen = ref(false)
const selectedItem = ref(null)
const loaded = ref(false)
const calloutIndex = ref(0)

const items = ref([
  {
    name: 'Insurance',
    gridCols: 5,
    subNav: [
      {
        name: 'Residential',
        description: 'Protect your home from flooding',
        icon: `<svg  viewBox="0 0 107 120" class="md:w-8 relative md:-top-[3px]" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M106.545 119.267H0.753906V40.7374L54.4238 0.960205L106.545 40.7656L106.545 119.267ZM73.7095 112.582H99.8606V44.0732L54.3628 9.32473L7.43855 44.1014V112.582H33.5863V49.9811H73.7095V112.582ZM40.271 112.582H67.0249V56.6657H40.271V112.582Z" fill="currentColor"></path>
</svg>`,
        path: '/residential-flood-insurance',
      },
      {
        name: 'Commercial',
        description: 'Safeguard your business assets',
        icon: `
        <svg viewBox="0 0 638 612" fill="currentColor" xmlns="http://www.w3.org/2000/svg"
         class="lg:h-10 relative lg:-top-[3px]">
<path d="M635.705 129.332L590.565 12.7518L572.869 0.632812H74.3835L57.3845 11.1708L2.83751 121.105L0.978516 131.655C4.75452 165.342 27.2555 193.194 58.3795 206.956V611.368H568.328V220.307C575.278 218.632 582.09 216.236 588.599 213.015C619.576 197.697 638.112 168.011 636.969 135.521L635.705 129.332ZM379.331 573.417H258.032V313.091H379.331V573.417ZM417.281 573.417V275.141H220.081V573.417H96.3295V215.978C98.4385 216.097 100.539 216.251 102.68 216.251C133.613 216.251 161.477 203.663 180.285 183.477C186.777 192.433 195.295 200.228 205.536 206.245C233.523 222.712 269.724 222.7 297.76 206.245C306.018 201.39 313.154 195.386 319.015 188.542C326.347 197.227 335.495 204.725 346.174 210.544C376.582 227.135 414.359 227.16 444.755 210.544C455.286 204.812 464.322 197.437 471.604 188.913C477.713 196.473 485.274 203.169 494.107 208.654C505.247 215.567 517.637 219.927 530.377 221.872V573.417H417.281ZM571.775 178.993C553.708 187.962 531.07 186.936 514.133 176.411C501.285 168.443 493.279 156.176 491.76 142.908C492.291 138.115 492.416 133.248 492.101 128.331L454.392 126.774C453.657 131.777 453.397 136.743 453.594 141.648C450.957 156.176 441.235 169.246 426.578 177.239C407.393 187.715 383.551 187.715 364.353 177.239C349.998 169.407 340.387 156.72 337.515 142.563C337.762 137.177 337.421 131.717 336.458 126.244L298.836 128.332C298.503 133.595 298.663 138.808 299.293 143.91C297.662 155.72 290.195 166.665 278.533 173.534C262.189 183.12 241.089 183.12 224.77 173.534C210.477 165.134 202.49 150.593 203.812 135.843C204.034 134.459 204.225 133.063 204.385 131.655L166.844 126.244C166.578 127.751 166.356 129.258 166.183 130.765C161.279 158.091 134.62 178.301 102.68 178.301C71.4375 178.301 45.2475 158.968 39.5285 132.543L86.1505 38.5818H559.866L599.044 139.758C598.464 156.016 588.236 170.865 571.775 178.993Z" />
</svg>`,
        path: '/commercial-flood-insurance',
      },
      {
        name: 'RCBAP',
        description: 'Specialty condo building insurance',
        icon: `
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1080 1080" style="enable-background:new 0 0 1080 1080" xml:space="preserve"
        class="lg:h-16 relative lg:-top-[3px]" ><path fill="currentColor" d="M540.088 331.508V210.435H179.36v659.13l721.28-.018V331.508H540.088zM217.019 831.893V248.094h284.867v583.799H217.019zm644.924-.506H540.595V370.205h321.348v461.182z"/><path fill="currentColor" d="M264.065 372.115h77.077v-77.089h-77.077v77.089zm26.274-50.803h24.528v24.529h-24.528v-24.529zM379.724 372.115h77.089v-77.089h-77.089v77.089zm26.274-50.803h24.528v24.529h-24.528v-24.529zM263.331 489.267h77.089v-77.089h-77.089v77.089zm26.287-50.802h24.529v24.528h-24.529v-24.528zM379.003 489.267h77.077v-77.089h-77.077v77.089zm26.274-50.802h24.528v24.528h-24.528v-24.528zM263.331 607.646h77.089v-77.089h-77.089v77.089zm26.287-50.803h24.529v24.529h-24.529v-24.529zM379.003 607.646h77.077v-77.089h-77.077v77.089zm26.274-50.803h24.528v24.529h-24.528v-24.529zM263.331 726.329h77.089V649.24h-77.089v77.089zm26.287-50.803h24.529v24.528h-24.529v-24.528zM379.003 726.329h77.077V649.24h-77.077v77.089zm26.274-50.803h24.528v24.528h-24.528v-24.528zM690.725 412.658h-77.089v77.089h77.089v-77.089zm-26.274 50.816h-24.528v-24.529h24.528v24.529zM806.397 412.658h-77.089v77.089h77.089v-77.089zm-26.287 50.816h-24.528v-24.529h24.528v24.529zM690.004 529.811h-77.089V606.9h77.089v-77.089zm-26.274 50.814h-24.528v-24.528h24.528v24.528zM805.663 529.811h-77.077V606.9h77.077v-77.089zm-26.274 50.814h-24.528v-24.528h24.528v24.528zM690.004 648.189h-77.089v77.089h77.089v-77.089zm-26.274 50.816h-24.528v-24.529h24.528v24.529zM805.663 648.189h-77.077v77.089h77.077v-77.089zm-26.274 50.816h-24.528v-24.529h24.528v24.529z"/></svg>

`,
        path: '/rcbap-flood-insurance',
      },
      {
        name: 'Excess',
        description: 'Coverage above an NFIP policy',
        icon: `
<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="lg:w-10 lg:h-10 relative lg:-top-[3px]">
  <path stroke-linecap="round" stroke-linejoin="round" d="M9 12.75 11.25 15 15 9.75m-3-7.036A11.959 11.959 0 0 1 3.598 6 11.99 11.99 0 0 0 3 9.749c0 5.592 3.824 10.29 9 11.623 5.176-1.332 9-6.03 9-11.622 0-1.31-.21-2.571-.598-3.751h-.152c-3.196 0-6.1-1.248-8.25-3.285Z" />
</svg>

`,
        path: '/excess-flood-insurance',
      },

      {
        name: 'Jumpstart',
        description: 'Parametric earthquake insurance',
        icon: `<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="lg:w-10 lg:h-10 relative lg:-top-[3px]">
        <path stroke-linecap="round" stroke-linejoin="round" d="m6.115 5.19.319 1.913A6 6 0 0 0 8.11 10.36L9.75 12l-.387.775c-.217.433-.132.956.21 1.298l1.348 1.348c.21.21.329.497.329.795v1.089c0 .426.24.815.622 1.006l.153.076c.433.217.956.132 1.298-.21l.723-.723a8.7 8.7 0 0 0 2.288-4.042 1.087 1.087 0 0 0-.358-1.099l-1.33-1.108c-.251-.21-.582-.299-.905-.245l-1.17.195a1.125 1.125 0 0 1-.98-.314l-.295-.295a1.125 1.125 0 0 1 0-1.591l.13-.132a1.125 1.125 0 0 1 1.3-.21l.603.302a.809.809 0 0 0 1.086-1.086L14.25 7.5l1.256-.837a4.5 4.5 0 0 0 1.528-1.732l.146-.292M6.115 5.19A9 9 0 1 0 17.18 4.64M6.115 5.19A8.965 8.965 0 0 1 12 3c1.929 0 3.716.607 5.18 1.64" />
      </svg>
      `,
        path: 'https://www.jumpstartinsurance.com/',
        target: '_blank',
      },
    ],
  },
  {
    name: 'Resources',
    gridCols: 5,
    subNav: [
      {
        name: 'Blog',
        description: 'Articles about flood insurance',
        icon: `<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="lg:w-10 lg:h-10 relative lg:-top-[3px]">
  <path stroke-linecap="round" stroke-linejoin="round" d="M19.5 14.25v-2.625a3.375 3.375 0 0 0-3.375-3.375h-1.5A1.125 1.125 0 0 1 13.5 7.125v-1.5a3.375 3.375 0 0 0-3.375-3.375H8.25m0 12.75h7.5m-7.5 3H12M10.5 2.25H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 0 0-9-9Z" />
</svg>
`,
        path: '/blog',
      },
      {
        name: 'FAQs',
        description: 'Answers to common questions',
        icon: `<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="lg:w-10 lg:h-10 relative lg:-top-[3px]">
  <path stroke-linecap="round" stroke-linejoin="round" d="m11.25 11.25.041-.02a.75.75 0 0 1 1.063.852l-.708 2.836a.75.75 0 0 0 1.063.853l.041-.021M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Zm-9-3.75h.008v.008H12V8.25Z" />
</svg>
`,
        path: '/faqs',
      },
      {
        name: 'Support',
        description: 'We are here to help',
        icon: `<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="lg:w-10 lg:h-10 relative lg:-top-[3px]">
  <path stroke-linecap="round" stroke-linejoin="round" d="M16.712 4.33a9.027 9.027 0 0 1 1.652 1.306c.51.51.944 1.064 1.306 1.652M16.712 4.33l-3.448 4.138m3.448-4.138a9.014 9.014 0 0 0-9.424 0M19.67 7.288l-4.138 3.448m4.138-3.448a9.014 9.014 0 0 1 0 9.424m-4.138-5.976a3.736 3.736 0 0 0-.88-1.388 3.737 3.737 0 0 0-1.388-.88m2.268 2.268a3.765 3.765 0 0 1 0 2.528m-2.268-4.796a3.765 3.765 0 0 0-2.528 0m4.796 4.796c-.181.506-.475.982-.88 1.388a3.736 3.736 0 0 1-1.388.88m2.268-2.268 4.138 3.448m0 0a9.027 9.027 0 0 1-1.306 1.652c-.51.51-1.064.944-1.652 1.306m0 0-3.448-4.138m3.448 4.138a9.014 9.014 0 0 1-9.424 0m5.976-4.138a3.765 3.765 0 0 1-2.528 0m0 0a3.736 3.736 0 0 1-1.388-.88 3.737 3.737 0 0 1-.88-1.388m2.268 2.268L7.288 19.67m0 0a9.024 9.024 0 0 1-1.652-1.306 9.027 9.027 0 0 1-1.306-1.652m0 0 4.138-3.448M4.33 16.712a9.014 9.014 0 0 1 0-9.424m4.138 5.976a3.765 3.765 0 0 1 0-2.528m0 0c.181-.506.475-.982.88-1.388a3.736 3.736 0 0 1 1.388-.88m-2.268 2.268L4.33 7.288m6.406 1.18L7.288 4.33m0 0a9.024 9.024 0 0 0-1.652 1.306A9.025 9.025 0 0 0 4.33 7.288" />
</svg>
`,
        path: '/support',
      },

      {
        name: 'Research Group',
        description: 'Commentary & Insights',
        icon: `
<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="lg:w-10 lg:h-10 relative lg:-top-[3px]">
  <path stroke-linecap="round" stroke-linejoin="round" d="M9.75 3.104v5.714a2.25 2.25 0 0 1-.659 1.591L5 14.5M9.75 3.104c-.251.023-.501.05-.75.082m.75-.082a24.301 24.301 0 0 1 4.5 0m0 0v5.714c0 .597.237 1.17.659 1.591L19.8 15.3M14.25 3.104c.251.023.501.05.75.082M19.8 15.3l-1.57.393A9.065 9.065 0 0 1 12 15a9.065 9.065 0 0 0-6.23-.693L5 14.5m14.8.8 1.402 1.402c1.232 1.232.65 3.318-1.067 3.611A48.309 48.309 0 0 1 12 21c-2.773 0-5.491-.235-8.135-.687-1.718-.293-2.3-2.379-1.067-3.61L5 14.5" />
</svg>

`,
        path: '/research',
      },

      {
        name: 'Newsroom',
        description: 'View our latest news',
        icon: `<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="lg:w-10 lg:h-10 relative lg:-top-[3px]">
  <path stroke-linecap="round" stroke-linejoin="round" d="M12 7.5h1.5m-1.5 3h1.5m-7.5 3h7.5m-7.5 3h7.5m3-9h3.375c.621 0 1.125.504 1.125 1.125V18a2.25 2.25 0 0 1-2.25 2.25M16.5 7.5V18a2.25 2.25 0 0 0 2.25 2.25M16.5 7.5V4.875c0-.621-.504-1.125-1.125-1.125H4.125C3.504 3.75 3 4.254 3 4.875V18a2.25 2.25 0 0 0 2.25 2.25h13.5M6 7.5h3v3H6v-3Z" />
</svg>
`,
        path: '/newsroom',
      },
    ],
  },
  {
    name: 'About',
    subNav: [
      {
        name: 'About',
        description: 'About Neptune and our leadership team',
        icon: `<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="lg:w-10 lg:h-10 relative lg:-top-[3px]">
  <path stroke-linecap="round" stroke-linejoin="round" d="M18 18.72a9.094 9.094 0 0 0 3.741-.479 3 3 0 0 0-4.682-2.72m.94 3.198.001.031c0 .225-.012.447-.037.666A11.944 11.944 0 0 1 12 21c-2.17 0-4.207-.576-5.963-1.584A6.062 6.062 0 0 1 6 18.719m12 0a5.971 5.971 0 0 0-.941-3.197m0 0A5.995 5.995 0 0 0 12 12.75a5.995 5.995 0 0 0-5.058 2.772m0 0a3 3 0 0 0-4.681 2.72 8.986 8.986 0 0 0 3.74.477m.94-3.197a5.971 5.971 0 0 0-.94 3.197M15 6.75a3 3 0 1 1-6 0 3 3 0 0 1 6 0Zm6 3a2.25 2.25 0 1 1-4.5 0 2.25 2.25 0 0 1 4.5 0Zm-13.5 0a2.25 2.25 0 1 1-4.5 0 2.25 2.25 0 0 1 4.5 0Z" />
</svg>

`,
        path: '/about',
      },

      {
        name: 'Careers',
        description: 'Growth perspective and opportunities with us',
        icon: `<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="lg:w-10 lg:h-10 relative lg:-top-[3px]">
  <path stroke-linecap="round" stroke-linejoin="round" d="M4.26 10.147a60.438 60.438 0 0 0-.491 6.347A48.62 48.62 0 0 1 12 20.904a48.62 48.62 0 0 1 8.232-4.41 60.46 60.46 0 0 0-.491-6.347m-15.482 0a50.636 50.636 0 0 0-2.658-.813A59.906 59.906 0 0 1 12 3.493a59.903 59.903 0 0 1 10.399 5.84c-.896.248-1.783.52-2.658.814m-15.482 0A50.717 50.717 0 0 1 12 13.489a50.702 50.702 0 0 1 7.74-3.342M6.75 15a.75.75 0 1 0 0-1.5.75.75 0 0 0 0 1.5Zm0 0v-3.675A55.378 55.378 0 0 1 12 8.443m-7.007 11.55A5.981 5.981 0 0 0 6.75 15.75v-1.5" />
</svg>

`,
        path: '/careers',
      },
      {
        name: 'Technology',
        description: 'Experience Triton AI',
        icon: `<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="lg:w-10 lg:h-10 relative lg:-top-[3px]">
  <path stroke-linecap="round" stroke-linejoin="round" d="M8.25 3v1.5M4.5 8.25H3m18 0h-1.5M4.5 12H3m18 0h-1.5m-15 3.75H3m18 0h-1.5M8.25 19.5V21M12 3v1.5m0 15V21m3.75-18v1.5m0 15V21m-9-1.5h10.5a2.25 2.25 0 0 0 2.25-2.25V6.75a2.25 2.25 0 0 0-2.25-2.25H6.75A2.25 2.25 0 0 0 4.5 6.75v10.5a2.25 2.25 0 0 0 2.25 2.25Zm.75-12h9v9h-9v-9Z" />
</svg>

`,
        path: '/technology',
      },
    ],
  },
  {
    name: 'Claims',
    path: '/report-a-claim',
  },
])
const allSubNavs = [
  ...items.value.reduce((acc, item) => {
    if (item.subNav) {
      acc = acc.concat(item.subNav)
    }
    return acc
  }, []),
  {
    name: 'Claims',
    path: '/report-a-claim',
    description: 'Report a claim',
    icon: `<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="lg:w-10 lg:h-10 relative lg:-top-[3px]">
  <path stroke-linecap="round" stroke-linejoin="round" d="M3 3v1.5M3 21v-6m0 0 2.77-.693a9 9 0 0 1 6.208.682l.108.054a9 9 0 0 0 6.086.71l3.114-.732a48.524 48.524 0 0 1-.005-10.499l-3.11.732a9 9 0 0 1-6.085-.711l-.108-.054a9 9 0 0 0-6.208-.682L3 4.5M3 15V4.5" />
</svg>`,
  },
]
const selectedSubNav = ref(null)
const selectedIndex = ref(null)

// Ref for the blue div
const blueDiv = ref(null)

function onBeforeEnter(el) {
  gsap.set(el, {
    scaleY: 0,
  })

  gsap.set(el.querySelectorAll('a'), {
    opacity: 0,
    y: 8,
  })
}

// called one frame after the element is inserted.
// use this to start the entering animation.
function onEnter(el, done) {
  // call the done callback to indicate transition end
  // optional if used in combination with CSS
  gsap.to(el.querySelectorAll('a'), {
    duration: 0.3,
    delay: 0.1,
    ease: 'expo.out',
    opacity: 1,
    stagger: 0.05,
    y: 0,
    onComplete: () => {
      done()
    },
  })

  gsap.to(el, {
    duration: 0.3,
    ease: 'expo.out',
    scaleY: 1,
  })
}

// function onLeave(el, done) {
//   gsap.to(el.querySelectorAll('a'), {
//     duration: 0.2,
//     ease: 'expo.out',
//     opacity: 0,
//     stagger: 0.05,
//     y: -8,
//   })

//   gsap.to(el, {
//     duration: 0.2,
//     ease: 'expo.in',
//     scaleY: 0,
//     delay: 0.1,
//     onComplete: () => {
//       done()
//     },
//   })
// }

// Animate selection function
function animateSelection(event, index) {
  gsap.killTweensOf(blueDiv.value)
  const targetElement = event.currentTarget
  const rect = targetElement.getBoundingClientRect()
  // Assume blueDiv.value is the element you're animating
  // Find the reference element
  const referenceElement = document.querySelector('.subnav')

  // Get the bounding rectangle of both elements
  const referenceElementRect = referenceElement.getBoundingClientRect()

  // Calculate the position relative to the reference element
  const relativeY = rect.top - referenceElementRect.top + window.scrollY

  if (selectedIndex.value === null) {
    gsap.set(blueDiv.value, {
      x: rect.left + window.scrollX, // Adjust for scroll
      y: 0,
    })
  }

  selectedIndex.value = index

  // Use GSAP to animate the element to the new position
  gsap.to(blueDiv.value, {
    duration: 0.4, // Adjust duration as needed
    x: rect.left + window.scrollX, // Adjust for scroll
    y: relativeY,
    height: rect.height,
    scale: 1,
    ease: 'expo.inOut', // Choose an easing function
  })
}

function animateSelectionOut() {
  gsap.killTweensOf(blueDiv.value)

  gsap.to(blueDiv.value, {
    duration: 0.2, // Adjust duration as needed
    scale: 0,
    ease: 'expo.in', // Choose an easing function
  })

  selectedIndex.value = null
}

onMounted(async () => {
  await nextTick()
  await setBlueDivHeight()

  const currentPath = window.location.pathname // Gets the current path
})

async function setBlueDivHeight(item) {
  await nextTick()

  const firstAnchorTag = document.querySelector('.subnav a') // Assuming it's the first anchor in your component
  if (firstAnchorTag) {
    const rect = firstAnchorTag.getBoundingClientRect()
    // Set initial size and position of blueDiv without animation
    console.log(rect.height)
    gsap.set(blueDiv.value, {
      width: rect.width, // Match width
      height: rect.height, // Match height
      y: rect.height / 2,
    })
  }
}
</script>

<style>
header .menu-item-has-children > a:after {
  /*  content: url('../assets/chevronDown.svg');*/
  @apply w-6 h-6 ml-1;
}

header .menu li a {
  @apply text-brand-blue text-sm font-wide inline-flex relative;
}

header .menu li {
  @apply p-1.5 first:mt-4;
}

header .sub-menu {
  @apply hidden;
}

@screen lg {
  header .menu li {
    @apply last:mt-4;
  }
  #desktopHeader .menu-header-container ul {
    @apply flex space-x-8 items-center;
  }

  #desktopHeader .menu-header-container .sub-menu {
    @apply hidden space-x-0 items-center;
  }

  header a {
    @apply relative;
  }

  header .sub-menu {
    @apply absolute hidden bg-white min-w-[180px] max-w-[534px] shadow-sm rounded-md border border-neutral-200;
  }

  header .sub-menu a {
    @apply w-full flex p-2;
  }

  header .sub-menu li {
    @apply p-0;
  }

  header .sub-menu a:hover {
    @apply bg-gray-100;
  }

  header .menu-item-has-children:hover > .sub-menu {
    @apply !grid;
  }

  header .btn-teal {
    @apply inline-block;
  }

  .btn-teal a::before {
    @apply !hidden;
  }

  .menu li {
    @apply p-0 !mt-0;
  }

  .menu .btn-teal,
  .hs-button {
    @apply !pr-9 !py-2.5;
  }
}
</style>
