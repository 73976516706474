<template>
  <img
    src="@/assets/face-blue.svg"
    alt=""
    class="absolute -left-[100px] w-[970px] opacity-[0.10] z-10 mix-blend-difference pointer-events-none"
  />
  <section class="pt-32 pb-40 px-6 relative rounded-b-[50px]">
    <div class="absolute inset-0 bg-brand-blue rounded-b-[50px]"></div>
    <div class="text-center relative z-10">
      <h2
        class="text-[24px] md:text-[48px] font-wide font-extrabold tracking-[2px] max-w-[420px] md:max-w-[789px] leading-tight mb-5 opacity-0 translate-y-8 uppercase text-white mx-auto text-white"
        v-intersection-observer="
          ([{ isIntersecting, target }]) => {
            if (isIntersecting) {
              gsap.to(target, {
                duration: 1.4,
                opacity: 1,
                y: 0,
                ease: 'expo',
                delay: 0.4,
              })
            }
          }
        "
      >
        Research Group
      </h2>
      <div
        class="mx-auto max-w-[482px] w-full relative opacity-0 translate-y-8"
        v-intersection-observer="
          ([{ isIntersecting, target }]) => {
            if (isIntersecting) {
              gsap.to(target, {
                duration: 1.4,
                opacity: 1,
                y: 0,
                ease: 'expo',
                delay: 0.5,
              })
            }
          }
        "
      >
        <p class="text-lg text-white max-w-sm mx-auto">
          Commentary and Insights on the flood insurance market.
        </p>
      </div>
    </div>
  </section>
  <div class="bg-white py-16">
    <div class="mx-auto max-w-7xl px-6 lg:px-8">
      <div class="mx-auto max-w-2xl lg:max-w-4xl">
        <div class="mt-16 space-y-20 lg:mt-20 lg:space-y-20">
          <article
            v-for="post in data.posts"
            :key="post.id"
            class="relative isolate flex flex-col gap-8 lg:flex-row"
          >
            <div
              class="relative aspect-[16/9] sm:aspect-[2/1] lg:aspect-square lg:w-64 lg:shrink-0"
            >
              <a
                :href="
                  post.acf.original_source_url
                    ? post.acf.original_source_url
                    : post.link
                "
                target="_blank"
              >
                <div
                  v-if="post.acf.publication_logo"
                  class="bg-gray-50 h-full w-full rounded-2xl absolute inset-0 flex items-center justify-center p-4"
                >
                  <img :src="post.acf.publication_logo.url" alt="" class="" />
                </div>

                <img
                  v-else-if="post.imageUrl"
                  :src="post.imageUrl"
                  alt=""
                  class="absolute inset-0 h-full w-full rounded-2xl bg-gray-50 object-cover"
                />
                <div
                  v-else
                  class="absolute inset-0 h-full w-full rounded-2xl bg-gray-50 object-cover flex items-center justify-center"
                >
                  <img
                    src="@/assets/face.svg"
                    alt=""
                    class="w-32 relative left-[22px] opacity-50"
                  />
                </div>
              </a>
              <div
                class="absolute inset-0 rounded-2xl ring-1 ring-inset ring-gray-900/10 pointer-events-none"
              />
            </div>
            <div>
              <div class="flex items-center gap-x-4 text-xs">
                <time :datetime="post.datetime" class="text-gray-500">{{
                  post.date
                }}</time>
                <a
                  :href="
                    post.acf.original_source_url
                      ? post.acf.original_source_url
                      : post.link
                  "
                  target="_blank"
                  class="relative z-10 rounded-full bg-gray-50 px-3 py-1.5 font-medium text-gray-600 hover:bg-gray-100"
                  >{{ post.category.title }}</a
                >
              </div>
              <div class="group relative max-w-xl">
                <h3
                  class="mt-3 text-lg font-extrabold leading-6 text-gray-900 group-hover:text-gray-600 uppercase"
                >
                  <a
                    target="_blank"
                    :href="
                      post.acf.original_source_url
                        ? post.acf.original_source_url
                        : post.link
                    "
                  >
                    <span class="absolute inset-0" />
                    {{ post.title }}
                  </a>
                </h3>
                <p class="mt-5 text-sm leading-6 text-gray-600">
                  {{ post.excerpt }}
                </p>
              </div>
            </div>
          </article>
        </div>
      </div>
    </div>
  </div>
  <CallToAction class="mt-24" :baseUrl="data.baseUrl" />
</template>

<script setup>
import { reactive, ref } from 'vue'
import gsap from 'gsap'
import { vIntersectionObserver } from '@vueuse/components'
import CallToAction from '@/components/CallToAction.vue'
const { data } = defineProps(['data'])
</script>
